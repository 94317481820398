import { createTheme } from '@mui/material/styles';

const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  white: '#fff',
  primary: {
    main: '#1d4d05',
    light: '#345f1e',
    dark: '#08622c',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#FF9800',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#1e2022',
    secondary: '#677788',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f7f7f7',
    level1: '#ffffff',
    alternate: '#f7faff',
  },
};

const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  white: '#fff',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark',
  primary: {
    main: '#fff',
    light: '#2196f3',
    dark: '#0d47a1',
    contrastText: '#0A7A37',
  },
  secondary: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
    alternate: '#242D44',
  },
};

export default (mode = 'light') =>
  createTheme({
    palette: mode === 'light' ? light : mode === 'dark' ? dark : impersonating,

    typography: {
      fontFamily: '"Inter", sans-serif',

      button: {
        textTransform: 'none',
        fontWeight: 'medium',
      },
      body1: {
        lineHeight: 1.9,
      },
    },

    zIndex: {
      appBar: 1200,
      drawer: 1300,
      onboarding: 2000,
    },

    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
          input: {
            borderRadius: 5,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          },
        },
      },
    },
  });
