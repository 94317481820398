import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { MainLogo } from './MainLogo';
import Head from 'next/head';

export const PageLoading = ({
  title = '',
  message = '',
  pageTitle = 'Rejigg',
}) => {
  return (
    <>
      <Head>{pageTitle && <title>{pageTitle}</title>}</Head>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <MainLogo />

          <CircularProgress size={30} sx={{ mt: 1, color: 'primary.main' }} />

          <Typography component={'p'} variant={'body1'} mb={1}>
            {title}
          </Typography>

          <Typography component={'p'} variant={'body2'}>
            {message}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PageLoading;
