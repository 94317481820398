import { useEffect } from 'react';
import useUserStore from 'stores/userStore';

const useImpersonationCheck = (session) => {
  const checkImpersonation = useUserStore((state) => state.checkImpersonation);
  const isImpersonating = useUserStore((state) => state.isImpersonating);

  useEffect(() => {
    checkImpersonation(session);
  }, [session, checkImpersonation]);

  return isImpersonating;
};

export default useImpersonationCheck;
