import { useState, useEffect } from 'react';

const useThemeStyle = () => {
  const [themeStyle, setThemeStyle] = useState('light');

  useEffect(() => {
    try {
      const localTheme = window.localStorage.getItem('themeStyle');

      if (localTheme && localTheme !== themeStyle) {
        setThemeStyle(localTheme);
      }
    } catch {
      setThemeStyle('light');
    }
  }, [themeStyle]);

  return [themeStyle, setThemeStyle];
};

export default useThemeStyle;
