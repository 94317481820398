import React from 'react';

export const MainLogo = ({ height = 32, invert = false, href = null }) => {
  const logoStyle = { height };
  const logoSrc = invert
    ? '/images/rejigg-logo-white.png'
    : '/images/rejigg-logo.png';

  if (href) {
    return (
      <a href={href}>
        <img src={logoSrc} alt="rejigg" style={logoStyle} />
      </a>
    );
  }

  return <img src={logoSrc} alt="rejigg" style={logoStyle} />;
};
