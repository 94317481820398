import * as dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
var advancedFormat = require('dayjs/plugin/advancedFormat');
var relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

function defaultCallStartTime(now = dayjs()) {
    const dayFromNow = now.add(1, 'day');
    const minute = dayFromNow.minute();

    if (minute < 30) {
        return dayFromNow.minute(30);
    }

    const hour = dayFromNow.hour();
    return dayFromNow
        .hour(hour + 1)
        .minute(0);
}

function defaultCallTimes(start_time = defaultCallStartTime()) {
    return {
        start_time,
        end_time: start_time.add(30, 'minute'),
    }
}

function fromNowInDaysAndHours(date) {
    const now = dayjs();
    const diffInDays = date.diff(now, 'day');
    const diffInHours = date.subtract(diffInDays, 'day').diff(now, 'hour');

    let result = '';
    if (diffInDays > 0) {
      result += `in ${diffInDays} days`;
    } else if (diffInDays < 0) {
      result += `${-diffInDays} days ago`;
    }

    if (diffInHours > 0) {
      if (result.length > 0) result += ', ';
      result += `${diffInHours} hours`;
    } else if (diffInHours < 0) {
      if (result.length > 0) result += ', ';
      result += `${-diffInHours} hours ago`;
    }

    if (diffInHours === 0 && diffInDays === 0) {
      result += date.diff(now, 'minute') + ' minutes';
    }

    return result;
  }

/**
 * Formats a given date into a readable string.
 * If the date is in the current year, the format will be 'ddd, MMM D'.
 * If the date is not in the current year, the format will be 'ddd, MMM D, YYYY'.
 *
 * @param {string|Date|number} date - The date to format. Can be a string, Date object, or timestamp.
 * @returns {string} The formatted date string.
 */
function formatDate(date) {
    const currentYear = dayjs().year();
    const inputYear = dayjs(date).year();
    const formatString = inputYear === currentYear ? 'ddd, MMM D' : 'ddd, MMM D, YYYY';
    return dayjs(date).format(formatString);
}

export { dayjs, defaultCallTimes, fromNowInDaysAndHours, formatDate };
