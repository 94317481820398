import React, { useEffect } from 'react';

import { GoogleAnalytics } from 'nextjs-google-analytics';
import { SessionProvider } from 'next-auth/react';

import { AnalyticsProvider } from 'contexts/analyticsContext';
import { OnboardingProvider } from 'contexts/onboardingContext';

import { ConfirmProvider } from 'material-ui-confirm';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FrontContextProvider } from 'contexts/frontContext';
import { RealTimeProvider } from 'contexts/realTimeContext';

import theme from 'theme';

import { ServiceWorkerProvider } from 'contexts/serviceWorkerContext';
import CssBaseline from '@mui/material/CssBaseline';

import 'styles/globals.css';
import Hotkeys from 'components/Hotkeys';
import SessionHandler from 'components/SessionHandler';
import { SnackbarProvider } from 'notistack';

import useBusinessStore from 'stores/businessStore';
import Head from 'next/head';
import useThemeStyle from 'hooks/useThemeStyle';
import useImpersonationCheck from 'hooks/useImpersonationCheck';

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  const [themeStyle] = useThemeStyle();
  const isImpersonating = useImpersonationCheck(session);

  const fetchUserBusinesses = useBusinessStore((state) => state.fetchUserBusinesses);

  const enableAnalytics = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true';

  useEffect(() => {
    fetchUserBusinesses();
  }, [fetchUserBusinesses]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=0.1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover, maximum-scale=1"
        />
      </Head>

      <ThemeProvider theme={theme(themeStyle)}>
        {enableAnalytics && !isImpersonating && <GoogleAnalytics trackPageViews />}

        <SessionProvider session={session}>
          <SessionHandler userData={pageProps?.user}>
            <AnalyticsProvider disabled={!enableAnalytics || isImpersonating}>
              <ServiceWorkerProvider>
                <SnackbarProvider maxSnack={3}>
                  <RealTimeProvider>
                    <OnboardingProvider>
                      <ConfirmProvider>
                        <FrontContextProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CssBaseline />

                            <Hotkeys>
                              <Component {...pageProps} />
                            </Hotkeys>
                          </LocalizationProvider>
                        </FrontContextProvider>
                      </ConfirmProvider>
                    </OnboardingProvider>
                  </RealTimeProvider>
                </SnackbarProvider>
              </ServiceWorkerProvider>
            </AnalyticsProvider>
          </SessionHandler>
        </SessionProvider>
      </ThemeProvider>
    </>
  );
}
