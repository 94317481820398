import { createContext, useContext, useEffect } from 'react';
import { axios } from 'utils/axios';
import useUserStore from 'stores/userStore';

const AnalyticsContext = createContext({});

const AnalyticsProvider = ({ children, disabled }) => {
  const user = useUserStore((state) => state.user);
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);

  function identify(user) {
    window?.analytics?.identify(user.email, {
      app_view: user.app_view,
      is_owner: user.is_owner ? 'true' : 'false',
      is_searcher: user.is_searcher ? 'true' : 'false',
      is_staff: user.is_staff ? 'true' : 'false',
      email: user.email,
      last_name: user.last_name,
      first_name: user.first_name,
      has_rejigg_premium: user.has_rejigg_premium ? 'true' : 'false',
      stripe_customer_id: user.stripe_customer_id,
    });
  }

  const track = (event, properties, options) => {
    if (disabled === true) {
      return
    }

    if (options?.server === false) {
      window?.analytics?.track(event, properties);
    } else {
      axios.post('/track/', {
        event,
        properties,
      })
      .catch((err) => {
        console.error("POST /track/ failed:", err);
      });
    }
  };

  useEffect(() => {
    if (disabled === false
      && isAuthenticated === true
      && user?.email !== undefined
    ) {
      identify(user);
    }
  }, [user, isAuthenticated])

  return (
    <AnalyticsContext.Provider
      value={{
        track,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalyticsContext = () => {
  return useContext(AnalyticsContext);
};

export { AnalyticsProvider, useAnalyticsContext };
