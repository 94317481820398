import { createContext, useContext, useEffect, useState } from 'react';
import useUserStore from 'stores/userStore';

const ServiceWorkerContext = createContext(null);

export function useServiceWorker() {
  return useContext(ServiceWorkerContext);
}

export function ServiceWorkerProvider({ children }) {
  const user = useUserStore((state) => state.user);
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);

  const [isPushAllowed, setIsPushAllowed] = useState(false);

  function askForNotificationPermission() {
    if (!('Notification' in window)) {
      // alert('This browser does not support desktop notification');
      return;
    }

    if (Notification.permission === 'granted') {
      setupServiceWorker();
      setIsPushAllowed(true);
    } else if (Notification.permission !== 'denied') {
      // TODO: Add a button to ask for permission
    }
  }

  function setupServiceWorker() {
    if (
      isAuthenticated &&
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      window.workbox !== undefined
    ) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.active.postMessage({
            type: 'setup',
            user: user,
          });
        })
        .catch((error) => {
          console.error('Failed to register service worker', error);
        });
    }
  }

  useEffect(() => {
    askForNotificationPermission();
  }, [isAuthenticated]);

  const contextValue = {
    askForNotificationPermission,
    isPushAllowed,
  };

  return (
    <ServiceWorkerContext.Provider value={contextValue}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}
