import React, { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import useUserStore from 'stores/userStore';
import { PageLoading } from 'components/PageLoading';
import { dayjs } from 'utils/dayjs';

export default function SessionHandler({ children, userData }) {
  const [loading, setLoading] = useState(true);
  const { data: session, status } = useSession();
  const setSessionData = useUserStore((state) => state.setSessionData);
  const updateUserSettings = useUserStore((state) => state.updateUserSettings);
  const setUser = useUserStore((state) => state.setUser);
  const currentTimezone = dayjs.tz.guess();

  useEffect(() => {
    if (status === 'authenticated' && session) {
      setUser(userData);
      setSessionData(session, status);
      setLoading(false);
    } else if (status === 'unauthenticated') {
      setSessionData(null, status);
      setLoading(false);
    }
  }, [session, status]);

  useEffect(() => {
    if (userData && userData?.timezone !== currentTimezone) {
      updateUserSettings({ timezone: currentTimezone });
    }
  }, []);

  if (status === 'loading' || loading) {
    return <PageLoading />;
  }

  return children;
}
